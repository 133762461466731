import { calcTotalProductPrice } from "../../utility";
import { removeFromCart, updateCart } from "./cart";
import { cartItemIngredient } from "./cartItemIngredient";

export const cartItem = (item, listElement) => {
    const itemHtml = `
            <div class="row cart_item my-3">
                <div class="px-0 col-12 col-md-5 text-center ">
                    <div class="col-12 mb-3 cart_item_name m-auto sub_heading">${item.name}</div>
                    <div class="col-12 mb-1 cart_item_type m-auto">
                        ${item.type.type_label}:  <span style="font-weight: 600;">${item.type.price.toFixed(2)} DIN</span>
                    </div>
                    <div class="col-12 cart_item_ings">
                    </div>
                </div>
                <div class="my-3 my-md-0 px-0 col-12 col-md-2 text-center">
                    <div class="col-12 mx-auto d-flex flex-nowrap qty justify-content-between qty_control">
                        <div class="flex-grow-1 p-sm-1 p-md-2 qty_inc ripple_effect" >
                            <i class="bi bi-plus"></i>
                        </div>
                        <div class="col-4 p-sm-1 p-md-2 qty_value" >${item.qty}</div>
                        <div class="flex-grow-1 p-sm-1 p-md-2 qty_dec ripple_effect" >
                            <i class="bi bi-dash"></i>
                        </div>
                    </div>
                </div>
                <div class="mt-3 mt-md-0 px-0 col-6 col-md-2 text-center single_product_price">
                    ${calcTotalProductPrice(item.type.price, 1, item.selectedIngs).toFixed(2)} DIN
                </div>
                <div class="mt-3 mt-md-0 px-0 col-6 col-md-2 text-center total_product_price">
                    ${calcTotalProductPrice(item.type.price, item.qty, item.selectedIngs).toFixed(2)} DIN
                </div>
                <div class="mt-3 mt-md-0 px-0 col-12 col-md-1 text-center">
                    <i class="bi bi-trash remove_from_cart"></i>
                </div>
            </div>
    `;
    let itemElement = document.createElement('div');
    itemElement.innerHTML = itemHtml;
    itemElement = itemElement.children[0];
    
    const handleIncQty = e => {
        item.qty = item.qty + 1;
        updateQty();
    }
    const handleDecQty = e => {
        if(item.qty > 1)
        item.qty = item.qty - 1;
        updateQty();
    }
    
    const handleRemoveFromCart = e => {
        itemElement.classList.add('fadeAway');
        setTimeout(() => {
            removeFromCart(item.cartId)
        }, 190);
    }



    // qty
    const incQtyElement = itemElement.querySelector('.qty_inc');
    incQtyElement.addEventListener('click', handleIncQty)
    const decQtyElement = itemElement.querySelector('.qty_dec');
    decQtyElement.addEventListener('click', handleDecQty)

    // remove from cart
    const removeFromCartElement = itemElement.querySelector('.remove_from_cart');
    removeFromCartElement.addEventListener('click', handleRemoveFromCart)
    
    // update dom & cart
    const qtyValueElement = itemElement.querySelector('.qty_value');
    const singleProductPriceElement = itemElement.querySelector('.single_product_price');
    const totalProductPriceElement = itemElement.querySelector('.total_product_price');
    const updateQty = () => {
        qtyValueElement.innerHTML = item.qty;
        singleProductPriceElement.innerHTML = `${calcTotalProductPrice(item.type.price, 1, item.selectedIngs).toFixed(2)} DIN`;
        totalProductPriceElement.innerHTML = `${calcTotalProductPrice(item.type.price, item.qty, item.selectedIngs).toFixed(2)} DIN`;
        updateCart(item);
    }

    // render
    listElement.appendChild(itemElement);

    const ingsElement = itemElement.querySelector('.cart_item_ings')
    item.selectedIngs.forEach(ing => cartItemIngredient(ing, ingsElement, updateQty));
    
}