import { emptyCartElement, cartLayoutElement } from "./cartLayout";
import {  showPopup } from "../../ui/popup";
import { bindRippleEffectSingle } from "../../ui/rippleEffect";
import { cartItem } from "./cartItem";
import { getCartProducts } from "./cart";

export const  cartItemsList = () => {
    const cartItems = getCartProducts();
    if(cartItems.length < 1) {
        showPopup(emptyCartElement);
        return
    }
    const listElement = cartLayoutElement.querySelector('.cart_items_list');
    listElement.innerHTML = '';

    showPopup(cartLayoutElement)
    cartItems.forEach(item => cartItem(item, listElement));
    
    bindRippleEffectSingle(cartLayoutElement);
};