import 'bootstrap';
import { initCart } from './components/cart/cart.js';
import { initLocation } from './components/location/location.js';
import home from './pages/home.js';
import pizze from './pages/pizze.js';
import meni from './pages/meni.js';
import akcije from './pages/akcije.js';
import pola from './pages/pola-pola.js';
import burgeri from './pages/burgeri.js';
import sendvici from './pages/sendvici.js';
import { globalEvents, setActiveNavItem } from './utility.js';
import { hideLoader, showLoader } from './ui/loader.js';

const pages = {
    home,
    pizze,
    meni,
    akcije,
    pola,
    burgeri,
    sendvici,
}
const currentPage = document.body.className;
setActiveNavItem(currentPage);

const initApp = async () => {
    showLoader();
    await initCart();
    await initLocation();
    await pages[currentPage]();
    globalEvents()
    
    setTimeout(() =>  hideLoader(), 100)
}

initApp();
