import { host } from "../../config.js";
import { notify } from "../../ui/notification.js";
import { showPopup } from "../../ui/popup.js";
import { bindRippleEffect, bindRippleEffectSingle } from "../../ui/rippleEffect.js";
import { calcTotalProductPrice } from "../../utility.js";
import { addToCart } from "../cart/cart.js";
import { ingredient } from "./ingredient.js";

export const ingredientsList = (item, ings) => {
    const state = {
        selectedType: item.types[0],
        selectedIngs: [],
        qty:1
    };
    
    const productHtml = `
        <div class="col-12 px-0 mb-4">
            <div class="d-flex flex-nowrap col-12 h-100" data-id="${item.id}">
                <div class="col-5 p-0 p-md-2 d-flex align-items-center justify-content-center product_with_ings_image">
                    <img class="img-fluid" src="${host}img/products/${item.image}"" alt="">
                </div>
                <div class="col-7 px-0 d-flex flex-column justify-content-end product_with_ings_cart">
                    <div class="col-12 text-center mx-auto mb-3 mb-md-4 product_with_ings_name">${item.name}</div>
                    <div class="col-12 mb-3 mb-md-4">
                        <h5 class="mb-1">Sastav</h5>
                        <ul class="col-12 d-flex flex-wrap default_ingredient_list">
                            ${item.default_ingredients.map(default_ingredient => `
                                <li class="me-2 my-1 px-2 default_ingredient">${default_ingredient}</li>
                            `).join('')}
                        </ul>
                    </div>
                    <div class="col-12 mb-3 mb-md-4">
                        <select class="product_with_ings_select w-100  p-1 p-md-2" name="" id="">
                            ${item.types.map(type => `
                                <option class="product_with_ings_option" value="${type.type_value}">
                                    ${type.type_label} - ${type.price} DIN
                                </option>
                            `).join('')}
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-between">
                <div class="flex-grow-1 me-2">
                    <div class="col-12 col-sm-8 col-md-6 mx-auto d-flex flex-nowrap qty justify-content-between">
                        <div class="flex-grow-1 p-sm-1 p-md-2 qty_inc ripple_effect" >
                            <i class="bi bi-plus"></i>
                        </div>
                        <div class="col-4 p-sm-1 p-md-2 qty_value" >1</div>
                        <div class="flex-grow-1 p-sm-1 p-md-2 qty_dec ripple_effect" >
                            <i class="bi bi-dash"></i>
                        </div>
                    </div>
                </div>
                <button class="col-7 add_to_cart py-1 py-md-2 ripple_effect">PORUČI</button>
            </div>
        </div>
    `;
    // events callbacks
    const handleTypeSelect = e => {
        const selectedType = item.types.find(type => type.type_value === +e.target.value);
        state.selectedType = selectedType;
    }
    const handleIncQty = e => {
        state.qty = state.qty + 1;
        updateQty();
    }
    const handleDecQty = e => {
        if(state.qty > 1)
        state.qty = state.qty - 1;
        updateQty();
    }
    const handleAddToCart = e => {
        const cartItem = {}
        cartItem.productQty = state.qty;
        cartItem.productId = item.id;
        cartItem.type = state.selectedType.type_value;
        cartItem.selectedIngs = state.selectedIngs;
        cartItem.price = calcTotalProductPrice(state.selectedType.price, state.qty, state.selectedIngs);
        
        addToCart(cartItem);
        notify('Dodato u korpu!', 'success');
    }
    
    // update selected ingredients in state (from children)
    const updateSelectedIngs = (updatedIngredient) => {
        if(updatedIngredient.ingQty < 1) {
            state.selectedIngs = state.selectedIngs.filter(selectedIng => selectedIng.id !== updatedIngredient.id);
            return;
        }
        if(state.selectedIngs.length < 1) {
            state.selectedIngs.push(updatedIngredient);
            return;
        }
        const ingExists = state.selectedIngs.findIndex(selectedIng => selectedIng.id === updatedIngredient.id);
        if(ingExists !== -1) {
            state.selectedIngs[ingExists] = updatedIngredient;
        } else {
            state.selectedIngs.push(updatedIngredient)
        }
    }

    let productElement = document.createElement('div');
    productElement.innerHTML = productHtml;
    productElement = productElement.children[0];

    const ingsContainerElement = document.createElement('div');
    ingsContainerElement.classList.add('container-fluid', 'pt-4', 'pt-md-2', 'bg-white')
    const ingsListElement = document.createElement('div');
    ingsListElement.classList.add('row')

    showPopup(ingsContainerElement);

    ingsContainerElement.append(productElement);
    ingsContainerElement.append(ingsListElement);
    ings.forEach(ing => ingredient(ing, ingsListElement, updateSelectedIngs));
    
    bindRippleEffectSingle(productElement);

    // select type
    const selectElement = ingsContainerElement.querySelector('.product_with_ings_select');
    selectElement.addEventListener('change', handleTypeSelect);
    // qty
    const incQtyElement = ingsContainerElement.querySelector('.qty_inc');
    incQtyElement.addEventListener('click', handleIncQty)
    const decQtyElement = ingsContainerElement.querySelector('.qty_dec');
    decQtyElement.addEventListener('click', handleDecQty)
    // add to cart
    const addToCartElement = ingsContainerElement.querySelector('.add_to_cart');
    addToCartElement.addEventListener('click', handleAddToCart)


    

    // update qty value in dom
    const qtyValueElement = ingsContainerElement.querySelector('.qty_value');
    const updateQty = () => {
        qtyValueElement.innerHTML = state.qty;
    }

    // bindRippleEffect()
};
