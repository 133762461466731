export const locationRoadmap = `
        <h4 class="col-12 p-4 mx-auto mt-2 mt-md-3 d-flex justify-content-around align-items-center roadmap">
            <div class="roadmap_line mx-2 mx-sm-3 d-flex flex-grow-1">
            </div>

            <i class="fas fa-map-marker-alt outline_icon"></i>

            <div class="roadmap_line mx-2 mx-sm-3 d-flex flex-grow-1">
            </div>
        </h4>
    `
;