import { host } from "../../config.js"
import { bindRippleEffectSingle } from "../../ui/rippleEffect.js";
export const ingredient = (ing, ingsListElement, updateIngsArray) => {
    const state = {
        qty:0
    };
    const ingHtml = `
        <div class="col-4 col-sm-3 col-md-2 position-relative p-2" data-id="${ing.id}">
            <div class="ing h-100 d-flex flex-column justify-content-between">
                <div class="col-12 d-flex flex-nowrap ingQty justify-content-between">
                    <div class="flex-grow-1 p-sm-1 p-md-2 ingQty_inc ripple_effect" >
                        <i class="bi bi-plus"></i>
                    </div>
                    <div class="col-4 p-sm-1 p-md-2 ingQty_value" >1</div>
                    <div class="flex-grow-1 p-sm-1 p-md-2 ingQty_dec ripple_effect" >
                        <i class="bi bi-dash"></i>
                    </div>
                </div>
                <div class="col-12 h-100 d-flex flex-column justify-content-between toggle_ing">
                    <img class="img-fluid" src="${host}img/ings/${ing.image}"" alt="">
                    <div class="col-12 ">
                        <div class="col-12 text-center ingName">${ing.name}</div>
                        <div class="col-12 text-center ingPrice">${ing.price} DIN</div>
                    </div>
                </div>
            </div>
        </div>
    `;

    // event callbacks
    const handleToggleIng = e => {
        if(state.qty < 1) {
            state.qty = 1;
            ingQtyElement.classList.add('show_ing_qty', 'scaleIn');
        } else {
            state.qty = 0;
            ingQtyElement.classList.remove('show_ing_qty', 'scaleIn');
        }
        updateIngQty();
        updateSelectedIngredientsArray();
    }
    const handleIncIngQty = e => {
        state.qty = state.qty + 1;
        updateIngQty();
        updateSelectedIngredientsArray();
    }
    const handleDecIngQty = e => {
        if(state.qty > 0)
        state.qty = state.qty - 1;
        updateIngQty();
        updateSelectedIngredientsArray();
    }

    let ingElement = document.createElement('div');
    ingElement.innerHTML = ingHtml;
    ingElement = ingElement.children[0];

    ingsListElement.appendChild(ingElement);

    bindRippleEffectSingle(ingElement);

    const ingQtyElement = ingElement.querySelector('.ingQty');
    const toggleIngElement = ingElement.querySelector('.toggle_ing');
    toggleIngElement.addEventListener('click', handleToggleIng);

    // inc / dec qty
    const incIngQtyElement = ingElement.querySelector('.ingQty_inc');
    incIngQtyElement.addEventListener('click', handleIncIngQty);
    const decIngQtyElement = ingElement.querySelector('.ingQty_dec');
    decIngQtyElement.addEventListener('click', handleDecIngQty);

    
    // update ing qty value in dom
    const ingQtyValueElement = ingQtyElement.querySelector('.ingQty_value');
    const updateIngQty = () => {
        if(state.qty > 0) {
            ingQtyValueElement.innerHTML = state.qty;
        } else {
            ingQtyElement.classList.remove('show_ing_qty', 'scaleIn');
        }
    }

    // update selected ingredients array
    const updateSelectedIngredientsArray = () => {
        const ingQty = state.qty;
        const id = ing.id;
        const price = ing.price;
        const updatedIngredient = {
            id,
            ingQty,
            price
        }
        updateIngsArray(updatedIngredient);
    }

}