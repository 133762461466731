export const orderRoadmap = currentPhase => 
    `
        <h4 class="col-12 p-4 mx-auto mt-2 mt-md-3 d-flex justify-content-around align-items-center roadmap">
            <i class="
                fas fa-utensils 
                phase_completed
                outline_icon
            ">
            </i>

            <div class="roadmap_line mx-1 mx-sm-3 d-flex flex-grow-1 phase_completed">
            </div>

            <i class="
                fas fa-shopping-cart
                phase_completed
                ${currentPhase === 2 ? 'current_phase' : ''}
                outline_icon
            ">
            </i>

            <div class="roadmap_line mx-1 mx-sm-3 d-flex flex-grow-1 ${currentPhase > 2 ? 'phase_completed' : ''}">
            </div>

            <i class="
                fas fa-user 
                ${currentPhase > 2 ? 'phase_completed' : ''}
                ${currentPhase === 3 ? 'current_phase' : ''}
                outline_icon
            ">
            </i>

            <div class="roadmap_line mx-1 mx-sm-3 d-flex flex-grow-1 ${currentPhase > 3 ? 'phase_completed' : ''}">
            </div>

            <i class="
                fas fa-truck 
                ${currentPhase > 3 ? 'phase_completed' : ''}
                ${currentPhase === 4 ? 'current_phase' : ''}
                outline_icon
            ">
            </i>
        </h4>
    `
;