import { orderRoadmap } from "../../ui/orderRoadmap";

const cartLayoutHtml = `
    <div class="container-fluid m-0 p-0 cart_layout">
        ${orderRoadmap(2)}
        <div class="container-fluid m-0 p-2 p-md-3">
            <div class="col-12 cart_list_header flex-wrap d-none d-md-flex">
                <div class="col-12 col-md-5 px-0 text-start d-flex justify-content-center">NAZIV PROIZVODA</div>
                <div class="col-6 col-md-2 px-0 text-start d-flex justify-content-center">KOLIČINA</div>
                <div class="col-6 col-md-2 px-0 text-start d-flex justify-content-center">JED. CENA</div>
                <div class="col-6 col-md-2 px-0 text-start d-flex justify-content-center">UKUPNO</div>
                <div class="col-6 col-md-1 px-0 text-start d-flex justify-content-center">UKLONI</div>
            </div>
            <div class="col-12 cart_list_header flex-wrap d-flex d-md-none">
                <div class="col-12 col-md-5 px-0 text-start d-flex justify-content-center">PROIZVODI</div>
            </div>
            <div class="container-fluid cart_items_list">
            </div>
            
            <div class="col-12 flex-wrap d-flex">
                <div class="col-md-5 px-0 text-start d-none d-md-flex justify-content-center"></div>
                <div class="col-md-2 px-0 text-start d-none d-md-flex justify-content-center"></div>
                
                <div class="col-6 col-md-2 px-0 text-start d-flex justify-content-end">
                    <strong>UKUPNO:</strong>
                </div>
                <div class="col-6 col-md-2 px-0 text-start d-flex justify-content-center">
                    <strong><span class="total_to_pay"></span> DIN</strong>
                </div>
                </strong>
                <div class="col-md-1 px-0 text-start d-none d-md-flex justify-content-center"></div>
            </div>
            <div class="col-12 d-flex justify-content-center p-2">
                <button class="order_button py-1 py-md-2 ripple_effect">
                    PORUČUJEM <i class="bi bi-hand-index-thumb order_icon" ></i>
                </button>
            </div>
        </div>
    </div>
`;

let cartLayoutElement = document.createElement('div');
cartLayoutElement.innerHTML = cartLayoutHtml;
cartLayoutElement = cartLayoutElement.children[0];
    
const clickedOrder = () =>  console.log('order clicked');
const orderButtonElement = cartLayoutElement.querySelector('.order_button');
orderButtonElement.addEventListener('click', clickedOrder);

// empty cart
const emptyCartHtml = `
<div class="container-fluid m-0 p-0 h-50 cart_layout">
    <div class="container-fluid m-0 p-2 p-md-3 h-100 d-flex flex-column justify-content-between align-items-center fadeIn">
        ${orderRoadmap(2)}
        <div class="text-center empty_cart position-relative animateBorder">
            <div class="empty_cart_icon">
                <i 
                    class="
                        fas fa-shopping-cart
                        outline_icon
                    "
                    style="font-size:3rem;"
                >
                </i>
            </div>
            <div class="empty_cart_text">
                Vaša korpa je prazna!
            </div>
        </div>
        <div style="width:50px; height:50px;"></div>
    </div>
</div>
`;

let emptyCartElement = document.createElement('div');
emptyCartElement.innerHTML = emptyCartHtml;
emptyCartElement = emptyCartElement.children[0];

export {cartLayoutElement, emptyCartElement};
