import { host } from '../../config';
import { showPopup } from '../../ui/popup';
import { bindRippleEffectSingle } from '../../ui/rippleEffect';
import {locationLayoutElement} from './locationLayout';
import { deliver } from './deliver/deliver';
import { pickup } from './pickup/pickup';
import { getCities } from './location';

export const locationMenu = () => {
    const menuHtml = `
        <div class="location_menu">
            <div class="row d-flex justify-content-center gap-sm-1 delivery-content">
                <button class="col-6 col-sm-5 col-md-4 d-flex  justify-content-center align-items-center m-sm-2  px-0 py-3 py-sm-0 ripple_effect pokupi">
                        <div class="col-5 col-sm-4 d-flex justify-content-center p-2 pokupi-icon">
                            <img class="img-fluid" src="${host}img/POKUPI.png" alt="">
                        </div>
                        <div class="col-7 col-sm-8 d-flex justify-content-center p-3 pokupi-text">
                            POKUPI SAM
                        </div>
                </button>
                <button class="col-6 col-sm-5 col-md-4 d-flex  justify-content-center align-items-center m-sm-2  px-0 py-3 py-sm-0 ripple_effect dostava">
                        <div class="col-7 col-sm-8 d-flex justify-content-center p-3 dostava-text">
                            DOSTAVA
                        </div>
                        <div class="col-5 col-sm-4 d-flex justify-content-center p-2 dostava-icon">
                            <img class="img-fluid" src="${host}img/DOSTAVA.png" alt="">
                        </div>
                </button>
            </div>
        </div>
    `;
    const locationContentElement = locationLayoutElement.querySelector('.location_content');
    locationContentElement.innerHTML = menuHtml;

    // events
    const deliverButton = locationContentElement.querySelector('.dostava');
    const pickupButton = locationContentElement.querySelector('.pokupi');

    deliverButton.addEventListener('click', () => deliver());
    pickupButton.addEventListener('click', () => pickup());
    
    bindRippleEffectSingle(locationLayoutElement)
    showPopup(locationLayoutElement);
}