import { host } from '../../../../config';
import { showPopup } from '../../../../ui/popup';
import { bindRippleEffectSingle } from '../../../../ui/rippleEffect';
import { getLocationsInCity} from '../../location';
import { locationLayoutElement } from '../../locationLayout';
import { pickup } from '../pickup';
import { markerPopupCard } from './markerPopupCard';
import { restaurantCard } from './restaurantCard';

export const pickRestaurant = (city) => {
    const allRestaurantsInCity = getLocationsInCity(city);
    console.log({allRestaurantsInCity})

    const mapContainerHtml = `
        <div class="container-fluid p-0 pb-md-5 fadeInFast pickup map_container">
            <div class="col-12 d-flex align-items-center justify-content-between mb-md-5 locations_header">
                <div class=" d-flex align-items-center">
                    <div class=" d-flex align-items-center justify-content-center px-3 locations_header_icon blue_glow">
                        <img class="img-fluid" src="${host}img/POKUPI.png" alt="">
                    </div>
                    Izaberite restoran
                </div>
                <button class="d-flex align-items-stretch px-3 py-2 h-100 back_button">
                    <i class="fas fa-arrow-left d-flex"></i>
                </button>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div id="map">Google mapa</div>
                <div class="restaurant_list_container">
                    <div class="restaurant_list"></div>
                </div>
            </div>
        </div>
    `;
    let mapContainerElement = document.createElement('div');
    mapContainerElement.innerHTML = mapContainerHtml;
    mapContainerElement = mapContainerElement.children[0];

    const locationListElement = mapContainerElement.querySelector('.restaurant_list');
    allRestaurantsInCity.forEach((loc, index) => restaurantCard(loc, index, locationListElement));
    
    const backButtonElement =  mapContainerElement.querySelector('.back_button');
    backButtonElement.addEventListener('click', () => pickup())
    
    const locationContentElement = locationLayoutElement.querySelector('.location_content');
    locationContentElement.innerHTML = '';
    locationContentElement.appendChild(mapContainerElement);

    const markers = allRestaurantsInCity.map(restaurant => ({...restaurant, content: markerPopupCard(restaurant)}))

    bindRippleEffectSingle(locationLayoutElement)
    showPopup(locationLayoutElement);
    initMap(markers);
}