import { getCart, getTotal } from "../components/cart/cart.js";
import { cartButtonElement, mobileCartButtonElement } from "./domElements.js";

const cartPriceSpan = cartButtonElement.querySelector('.price_span');
const cartQtySpan = cartButtonElement.querySelector('.qty_span');

const mobileCartPriceSpan = mobileCartButtonElement.querySelector('.mobile_price_span');
const mobileCartQtySpan = mobileCartButtonElement.querySelector('.mobile_qty_span');

export const updateCartButton = () => {
    const cart = getCart();
    const qty = cart.length;
    const total = getTotal();

    cartPriceSpan.innerHTML = total.toFixed(2);
    cartQtySpan.innerHTML = qty;
    
    mobileCartPriceSpan.innerHTML = total.toFixed(2);
    mobileCartQtySpan.innerHTML = qty;
}
