import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import { productList } from '../components/products/productsList.js';
import { host } from '../config.js';
import { productListElement } from '../ui/domElements.js';

const sendvici = async () => {
    const location = JSON.parse(localStorage.getItem('location')) || null;

    if(location === null) {
        window.location = host + 'meni.php';
        return;
    }

    //api call based on location
    const data  = await $.ajax({
        url: `${host}dummy_data/products.json`,
        type: 'GET'
    })
    const products = data.filter(prod => prod.product_type === "sendvici");
    console.log({products})
    
    const extraIngs  = await $.ajax({
        url: `${host}dummy_data/extra-ings.json`,
        type: 'GET'
    });
    productListElement.innerHTML = '';

    productList(products, extraIngs);
}
export default sendvici;
