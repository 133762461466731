import { setLocation } from "../location.js";

export const deliverCityButton = (city, buttonsContainer) => {
    const buttonHtml = `<button class="col-8 col-sm-4 city_picker ripple_effect">${city.city_name}</button>`;

    let buttonElement = document.createElement('div');
    buttonElement.innerHTML = buttonHtml;
    buttonElement = buttonElement.children[0];

    const deliveryLocation = {...city}
    deliveryLocation.name = 'Dostava';
    deliveryLocation.type = 'deliver';
    
    buttonElement.addEventListener('click', () => setLocation(deliveryLocation))

    buttonsContainer.appendChild(buttonElement);
}