import { host } from '../../../config.js';
import { showPopup } from '../../../ui/popup.js';
import { bindRippleEffectSingle } from '../../../ui/rippleEffect.js';
import { deliverCityButton } from './deliverCityButton.js';
import {locationLayoutElement} from '../locationLayout.js';
import { getCities } from '../location.js';
import { locationMenu } from '../menu.js';

export const deliver = () => {
    const deliverHtml = `
        <div class="container-fluid deliver px-0 pb-5 fadeInFast">
            <div class="col-12 d-flex align-items-center justify-content-between mb-md-5 locations_header">
                <div class=" d-flex align-items-center">
                    <div class=" d-flex align-items-center justify-content-center px-3 locations_header_icon red_glow">
                        <img class="img-fluid" src="${host}img/DOSTAVA.png" alt="">
                    </div>
                    Izaberite grad za dostavu
                </div>
                <button class="d-flex align-items-stretch px-3 py-2 h-100 back_button">
                    <i class="fas fa-arrow-left d-flex"></i>
                </button>
            </div>
            <div class="col-12 my-3 locations_caption text-center">
                Izaberite grad za dostavu
            </div>
            <div class="row locations_content">
                <div class="col-12 d-flex flex-wrap gap-3 p-3 px-md-5 justify-content-center city_buttons">
                </div>
            </div>
        </div>
    `;
    let deliverElement = document.createElement('div');
    deliverElement.innerHTML = deliverHtml;
    deliverElement = deliverElement.children[0];
    
    const cities = getCities()
    const cityButtonsElement = deliverElement.querySelector('.city_buttons');
    cities.forEach(city => deliverCityButton(city, cityButtonsElement));

    const backButtonElement =  deliverElement.querySelector('.back_button');
    backButtonElement.addEventListener('click', () => locationMenu())

    const locationContentElement = locationLayoutElement.querySelector('.location_content');
    locationContentElement.innerHTML = '';
    locationContentElement.appendChild(deliverElement);
    
    bindRippleEffectSingle(locationLayoutElement)
    showPopup(locationLayoutElement);
}