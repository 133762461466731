import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import { host } from '../../config.js';
import { updateLocationButton, hideLocationButton } from '../../ui/locationButton.js';
import { locationButtonElement, mobileLocationButtonElement } from '../../ui/domElements.js';
import { locationMenu } from './menu.js';
import { resetCart } from '../cart/cart.js';

const state = {
    location: null,
    locations: [],
    cities: []
};
export const getLocation = () => state.location;
export const getLocations = () => state.locations;
export const getCities = () => state.cities;


export const initLocation = async () => {
    loadLocation();
    await fetchLocations();

    if(state.location) {
        updateLocationButton(state.location.city_name, state.location.name);
    } else {
        hideLocationButton();
    }

    locationButtonElement.addEventListener('click', locationMenu)
    mobileLocationButtonElement.addEventListener('click', locationMenu)
}
const loadLocation = () => state.location = JSON.parse(localStorage.getItem('location')) || null;
const fetchLocations = async () => {
    state.locations = await $.ajax({
        url: `${host}dummy_data/locations.json`,
        type: 'GET'
    });
    state.cities = state.locations
        .reduce((acc, item, i) => {
            if(i > 0) {
                const cityIdsInAcc = acc.map(item => item.city_id);
                if(cityIdsInAcc.includes(item.city_id))
                return acc;
            }
            acc = [...acc, item]
            return acc;
        }, [])
        .map(loc => ({city_id: loc.city_id, city_name: loc.city_name}));
        console.log(state.cities)
}

export const getLocationsInCity = city => state.locations.filter(loc => loc.city_id === city.city_id);

export const setLocation = location => {
    resetCart();
    localStorage.setItem('location', JSON.stringify(location));
    window.location = host + 'pizze.php';
}
