import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import { host } from "../../config.js"
import { productListElement } from '../../ui/domElements.js';
import { notify } from '../../ui/notification.js';
import { bindRippleEffectSingle } from '../../ui/rippleEffect.js';
import { calcTotalProductPrice } from '../../utility.js';
import { addToCart } from '../cart/cart.js';
import { ingredientsList } from '../extra-ings/ingredientsList.js';


export const productItem = (item, extraIngs) => {
    const state = {
        selectedType: item.types[0],
        qty:1,
        selectedIngs: []
    };

    const productHtml = `
        <div class="col-12 col-md-6 col-lg-4 ${item.product_type === "akcija" ? "action_product": "col-xl-3"} px-0 p-1 p-md-2">
            <div class="d-flex flex-nowrap d-md-block col-12 h-100 w-auto product" data-id="${item.id}">
                <div class="col-4 col-md-12 p-0 p-md-2 product_image text-center">
                    <img class="img-fluid" src="${host}img/products/${item.image}"" alt="">
                </div>
                <div class="col-8 col-md-12 px-3 py-2 d-flex flex-column justify-content-end product_cart">
                    <div class="col-12 text-center mb-2 product_name">${item.name}</div>
                    <div class="col-12">
                        <select class="product_select w-100  p-1 p-md-2" name="" id="">
                            ${item.types.map(type => `
                                <option class="product_option" value="${type.type_value}">
                                    ${type.type_label} - ${type.price} DIN
                                </option>
                            `).join('')}
                        </select>
                        <div class="col-12 d-flex justify-content-between mt-2">
                            <button class="${item.extra_ings ? '' : 'd-none'} col-5 extra_ings py-1 me-2 ripple_effect">Extra dodaci</button>
                            <button class="flex-grow-1 add_to_cart py-1 py-md-2 ripple_effect">PORUČI</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;
    
    // events callbacks
    const handleTypeSelect = e => {
        const selectedType = item.types.find(type => type.type_value === +e.target.value);
        state.selectedType = selectedType;
    }
    const handleExtraIngredients = e => {
        ingredientsList(item, extraIngs);
    }
    const handleAddToCart = e => {
        const cartItem = {}
        cartItem.productQty = state.qty;
        cartItem.productId = item.id;
        cartItem.type = state.selectedType.type_value;
        cartItem.selectedIngs = state.selectedIngs;
        cartItem.price = calcTotalProductPrice(state.selectedType.price, state.qty, state.selectedIngs);
        
        addToCart(cartItem);
        notify('Dodato u korpu!', 'success');
    }

    let productElement = document.createElement('div');
    productElement.innerHTML = productHtml;
    productElement = productElement.children[0];

    // append product
    productListElement.appendChild(productElement);
    bindRippleEffectSingle(productElement);
    
    // bind events
    const selectElement = productElement.querySelector('.product_select');
    selectElement.addEventListener('change', handleTypeSelect);

    const extraIngredientsButton = productElement.querySelector('.extra_ings');
    extraIngredientsButton.addEventListener('click', handleExtraIngredients);

    const addToCartButton = productElement.querySelector('.add_to_cart');
    addToCartButton.addEventListener('click', handleAddToCart);
}