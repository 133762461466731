import { host } from "../../../../config"
import { setLocation } from "../../location";

export const markerPopupCard = restaurant => {
    const cardHtml = `
        <div class="marker_popup_card fadeIn" style="width:250px">
            <div class="w-100 text-center pb-2 marker_popup_title">${restaurant.name} - ${restaurant.city_name}</div>
            <div class="w-100" >
                <img class="marker_popup_image"src="${host}img/marker-default.jpg" alt="caribic" />
            </div>
            <div class="w-100 d-flex flex-column align-items-center p-2 marker_popup_content">
                <div class="marker_popup_phone">${restaurant.phone}</div>
                <div class="marker_address">${restaurant.address}</div>
                <div class="marker_address">${restaurant.city_name}, ${restaurant.postal}</div>
                <button class="p-1 mt-2 marker_button ripple_effect">
                    <i class="bi bi-menu-down me-1 restaurant_icon"></i>Meni
                </button>
            </div>
        </div>
    `;
    
    let cardElement = document.createElement('div');
    cardElement.innerHTML = cardHtml;
    cardElement = cardElement.children[0];
    
    const markerButtonElement = cardElement.querySelector('.marker_button');
    const pickupLocation = {...restaurant}
    pickupLocation.type = 'pickup';

    const setLocationHandler = e => {
        e.stopPropagation();
        setLocation(pickupLocation)
    };
    markerButtonElement.addEventListener('click', setLocationHandler);

    return cardElement;
}