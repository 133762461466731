import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import { host } from '../../config.js';
import { updateCartButton } from '../../ui/cartButton.js';
import { cartButtonElement, mobileCartButtonElement } from '../../ui/domElements.js';
import { calcTotalProductPrice } from '../../utility.js';
import { cartItemsList } from './cartItemsList.js';

const state = {
    cart: [],
    allProducts: [],
    allIngs: [],
    cartProducts: [],
    total: 0,
};
export const getCart = () => state.cart;
export const getCartProducts = () => state.cartProducts;
export const getTotal = () => state.total;


export const initCart = async () => {
    await fetchProducts();
    calcTotalPrice();
    updateCartButton();
    cartButtonElement.addEventListener('click', handleOpenCart)
    mobileCartButtonElement.addEventListener('click', handleOpenCart)
}

const fetchProducts = async () => {
    state.cart = JSON.parse(localStorage.getItem('cart')) || [];
    //fake api ////
    state.allProducts  = await $.ajax({
        url: `${host}dummy_data/products.json`,
        type: 'GET'
    });
    state.allIngs  = await $.ajax({
        url: `${host}dummy_data/extra-ings.json`,
        type: 'GET'
    });
    ///////////////
    if(state.cart.length > 0) {
        // fake api call
        state.cartProducts = state.cart.map(cartItem => {
            const product = {...state.allProducts.find(item => item.id === cartItem.productId)};

            const cartProduct = {}
            cartProduct.cartId = cartItem.cartId;
            cartProduct.id = product.id;
            cartProduct.name = product.name;
            cartProduct.type = {...product.types.find(type => type.type_value === cartItem.type)};
            cartProduct.selectedIngs = cartItem.selectedIngs.map(selectedIng => {
                const foundIng = {...state.allIngs.find(ing => ing.id === selectedIng.id)}
                foundIng.ingQty = selectedIng.ingQty;
                return foundIng;
            });
            cartProduct.qty = cartItem.productQty;
            return cartProduct;
        });
    }
    console.log('products: ', state.cartProducts)
}

export const addToCart = item => {
    item.cartId = generateUniqueId();
    state.cart.push(item);
    localStorage.setItem('cart', JSON.stringify(state.cart));
    console.log(item)
    state.total += item.price;
    updateCartButton();
}

const calcTotalPrice = () => {
    state.total = state.cartProducts.reduce((acc, product) => {
        acc += calcTotalProductPrice(product.type.price, product.qty, product.selectedIngs);
        return acc;
    }, 0);
}

const updateTotalPriceInCart = () => {
    const priceElement = document.querySelector('.total_to_pay');
    if(priceElement)
    priceElement.innerHTML = state.total.toFixed(2);
}

// events
const handleOpenCart = async e => {
    await fetchProducts();
    cartItemsList();
    updateTotalPriceInCart();
};

export const updateCart = (item) => {
    const updatedCartItem = {};
    updatedCartItem.cartId = item.cartId;
    updatedCartItem.productId = item.id;
    updatedCartItem.productQty = item.qty;
    updatedCartItem.selectedIngs = item.selectedIngs.filter(selIng => selIng.ingQty > 0);
    updatedCartItem.type = item.type.type_value;

    state.cart = state.cart.map(cartItem => cartItem.cartId === updatedCartItem.cartId ? updatedCartItem : cartItem)
    localStorage.setItem('cart', JSON.stringify(state.cart));

    calcTotalPrice();
    updateCartButton();
    updateTotalPriceInCart();
}

export const removeFromCart = (cartId) => {
    state.cart = state.cart.filter(cartItem => cartItem.cartId !== cartId)
    localStorage.setItem('cart', JSON.stringify(state.cart));
    
    state.cartProducts = state.cartProducts.filter(product => product.cartId !== cartId)
    calcTotalPrice();
    updateCartButton();
    cartItemsList();

    updateTotalPriceInCart();
}

export const resetCart = () => localStorage.removeItem('cart');


// generate id
const generateUniqueId = () => {
    if(state.cart.length > 0) {
        const ids = state.cart.map(item => item.cartId);
        const maxIdValue = Math.max(...ids);
        const uniqueId = maxIdValue + 1;
        return uniqueId;
    }
    return 0;
}