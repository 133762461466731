import { locationRoadmap } from "../../ui/locationRoadmap";

const locationLayoutHtml = `
    <div class="container-fluid px-0 pt-sm-1 d-block location_layout">
        ${locationRoadmap}
        <div class="location_content">
        </div>
    </div>
`;

let locationLayoutElement = document.createElement('div');
locationLayoutElement.innerHTML = locationLayoutHtml;
locationLayoutElement = locationLayoutElement.children[0];

export {locationLayoutElement}