export const headerElement = document.querySelector('header');
export const footerElement = document.querySelector('.footer_content');

export const deliveryElement = document.querySelector('.choose_delivery');
export const menuBarElement = document.querySelector('.menu_bar_container');

export const cartButtonElement = document.querySelector('.cart_button');
export const mobileCartButtonElement = document.querySelector('.mobile_cart_button');
export const productListElement = document.querySelector('.product_list');

export const homePickupButton = document.querySelector('.pokupi');
export const homeDeliverButton = document.querySelector('.dostava');

export const meniPickupButton = document.querySelector('.main_content .pokupi');
export const meniDeliverButton = document.querySelector('.main_content .dostava');

export const locationButtonElement = document.querySelector('.location_button');
export const mobileLocationButtonElement = document.querySelector('.mobile_location_button');

export const loaderElement = document.getElementById('loader');
