import { deliver } from "../components/location/deliver/deliver";
import { getCities } from "../components/location/location";
import { pickup } from "../components/location/pickup/pickup";
import { footerElement, homeDeliverButton, homePickupButton } from "../ui/domElements";

const home = () => {
    footerElement.classList.remove('jamaica');
    const cities = getCities();
    homeDeliverButton.addEventListener('click', () => deliver(cities))
    homePickupButton.addEventListener('click', () => pickup(cities))

}

export default home;