import { host } from '../../../config';
import { showPopup } from '../../../ui/popup';
import { bindRippleEffectSingle } from '../../../ui/rippleEffect';
import { pickupCityButton } from './pickupCityButton';
import {locationLayoutElement} from '../locationLayout';
import { getCities } from '../location';
import { locationMenu } from '../menu';

export const pickup = () => {
    const pickupHtml = `
        <div class="container-fluid pickup px-0 pb-5 fadeInFast">
            <div class="col-12 d-flex align-items-center justify-content-between mb-md-5 locations_header">
                <div class=" d-flex align-items-center">
                    <div class=" d-flex align-items-center justify-content-center px-3 locations_header_icon blue_glow">
                        <img class="img-fluid" src="${host}img/POKUPI.png" alt="">
                    </div>
                    Caribic - pokupi sam
                </div>
                <button class="d-flex align-items-stretch px-3 py-2 h-100 back_button">
                    <i class="fas fa-arrow-left d-flex"></i>
                </button>
            </div>
            <div class="col-12 my-3 locations_caption text-center">
                U kom ste gradu?
            </div>
            <div class="row locations_content">
                <div class="col-12 d-flex flex-wrap gap-3 p-3 px-md-5 justify-content-center city_buttons">
                </div>
            </div>
        </div>
    `;
    let pickupElement = document.createElement('div');
    pickupElement.innerHTML = pickupHtml;
    pickupElement = pickupElement.children[0];

    const cities = getCities()

    const cityButtonsElement = pickupElement.querySelector('.city_buttons');
    cities.forEach(city => pickupCityButton(city, cityButtonsElement));

    const backButtonElement =  pickupElement.querySelector('.back_button');
    backButtonElement.addEventListener('click', () => locationMenu())
    
    const locationContentElement = locationLayoutElement.querySelector('.location_content');
    locationContentElement.innerHTML = '';
    locationContentElement.appendChild(pickupElement);
    
    bindRippleEffectSingle(locationLayoutElement)
    showPopup(locationLayoutElement);
}