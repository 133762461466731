import { loaderElement } from "./domElements";
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

export const showLoader = () => {
    $(loaderElement).show();
    // $('body').css('overflow', 'hidden')
}
export const hideLoader = () => {
    $(loaderElement).fadeOut();
    // $('body').css('overflow', 'auto')
}