import { headerElement } from "./ui/domElements";
import { bindRippleEffect } from "./ui/rippleEffect";

    // calc total product price
export const calcTotalProductPrice = (productPrice, qty, ings) => {
    const ingTotalPrice = ings.reduce((sum, ing) => {
        sum = sum + ing.price * ing.ingQty;
        return sum;
    }, 0);
    const total = (productPrice + ingTotalPrice) * qty;
    return total;
}

export const globalEvents = () => {
    document.addEventListener('scroll', handleNavigationSkew);
    bindRippleEffect()
}

// toggle navbar
let previousScrollTop = window.pageYOffset;
const handleNavigationSkew = e => {
    const currentScrollTop = window.scrollY;

    if(currentScrollTop > previousScrollTop) {
        headerElement.classList.remove('swing_in')
        headerElement.classList.add('swing_out')
    } 
    if (currentScrollTop < previousScrollTop) {
        headerElement.classList.remove('swing_out')
        headerElement.classList.add('swing_in')
    }
    
    previousScrollTop = window.scrollY;
}

export const setActiveNavItem = (itemClass) => {
    const allNavItems = document.querySelectorAll('.menu_nav_link');
    [...allNavItems].forEach(item => {
        if(item.classList.contains(itemClass)) {
            item.classList.add('menu_active');
        } else {
            item.classList.remove('menu_active');
        }
    })
    
}