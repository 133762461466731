import { deliver } from "../components/location/deliver/deliver";
import { getCities } from "../components/location/location";
import { pickup } from "../components/location/pickup/pickup";
import { host } from "../config";
import { meniDeliverButton, meniPickupButton } from "../ui/domElements";

const meni = () => {
    const location = JSON.parse(localStorage.getItem('location')) || null;

    if(location) {
        window.location = host + 'pizze.php';
        return;
    }

    const cities = getCities();
    meniDeliverButton.addEventListener('click', () => deliver(cities))
    meniPickupButton.addEventListener('click', () => pickup(cities))

}

export default meni;