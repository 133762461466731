import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import { locationButtonElement, mobileLocationButtonElement } from "./domElements.js";

const locationSpan = locationButtonElement.querySelector('.location');
const locationDescSpan = locationButtonElement.querySelector('.location_desc');

const mobileLocationSpan = mobileLocationButtonElement.querySelector('.location');
const mobileLocationDescSpan = mobileLocationButtonElement.querySelector('.location_desc');

export const updateLocationButton = (locationName, locationDesc) => {
    locationSpan.innerHTML = locationName;
    locationDescSpan.innerHTML = locationDesc;
    
    mobileLocationSpan.innerHTML = locationName;
    mobileLocationDescSpan.innerHTML = locationDesc;
}

export const hideLocationButton = () => {
    $(locationButtonElement).hide();
    $(mobileLocationButtonElement).hide();
}

