import { pickRestaurant } from "./map/pickRestaurant";

export const pickupCityButton = (city, buttonsContainer) => {
    const buttonHtml = `<button class="col-8 col-sm-4 city_picker ripple_effect">${city.city_name}</button>`;

    let buttonElement = document.createElement('div');
    buttonElement.innerHTML = buttonHtml;
    buttonElement = buttonElement.children[0];

    const pickRestaurantHandler = () => {
        pickRestaurant(city);
    }
    buttonElement.addEventListener('click', pickRestaurantHandler)

    buttonsContainer.appendChild(buttonElement);
}