import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import { productList } from '../components/products/productsList.js';
import { host } from '../config.js';
import { productListElement } from '../ui/domElements.js';

const akcije = async () => {
    const location = JSON.parse(localStorage.getItem('location')) || null;

    if(location === null) {
        window.location = host + 'meni.php';
        return;
    }

    //api call based on location
    const data  = await $.ajax({
        url: `${host}dummy_data/products.json`,
        type: 'GET'
    })
    const products = data.filter(prod => prod.product_type === "akcija");
    console.log({products})
    
    const extraIngs  = await $.ajax({
        url: `${host}dummy_data/extra-ings.json`,
        type: 'GET'
    });
    productListElement.innerHTML = '';

    productList(products, extraIngs);

    // put first action item in single line
    setActionListCustomLayout()
}
export default akcije;

const setActionListCustomLayout = () => {
    const listElement = document.querySelector('.product_list');
    const clonedListElement = $(listElement).clone();
    const firstActionELement = listElement.firstChild;
    $(clonedListElement)
    .html('')
    .prepend(firstActionELement)
    .removeClass('p-xl-5');
    const listParent = listElement.parentElement;
    $(listParent).prepend(clonedListElement);
}