import { setLocation } from "../../location";

export const restaurantCard = (restaurant, index, parentElement) => {
    const restaurantHtml = `
        <div class="d-flex flex-column align-items-center p-3 restaurant_card">
            <div class="restaurant_info"><strong>${restaurant.name}</strong></div>
            <div class="restaurant_info">${restaurant.phone}</div>
            <div class="restaurant_info">${restaurant.address}</div>
            <div class="restaurant_info">${restaurant.city_name}, ${restaurant.postal}</div>
            <button class="mt-2 restaurant_button ripple_effect">
                <i class="bi bi-menu-down me-1 restaurant_icon"></i>Meni
            </button>
            <a 
                href="https://www.google.com/maps/search/?api=1&query=${restaurant.coords.lat},${restaurant.coords.lng}&z=11" 
                target="_blank"
                class="ms-auto mt-2 px-2 restaurant_map_link"
            >
                <i class="bi bi-geo-alt-fill"></i>
                Kojim putem?
            </a>
        </div>
    `;
    let restaurantElement = document.createElement('div');
    restaurantElement.innerHTML = restaurantHtml;
    restaurantElement = restaurantElement.children[0];

    const cardClickedHandler = () => {
        const markerButtons = getMarkerButtons();
        const markerButton = markerButtons[index];
        google.maps.event.trigger( markerButton, 'click' );
    }
    restaurantElement.addEventListener('click', cardClickedHandler);

    const restaurantButtonElement = restaurantElement.querySelector('.restaurant_button');
    const pickupLocation = {...restaurant}
    pickupLocation.type = 'pickup';

    const setLocationHandler = e => {
        e.stopPropagation();
        setLocation(pickupLocation)
    };
    restaurantButtonElement.addEventListener('click', setLocationHandler)

    parentElement.appendChild(restaurantElement);
}