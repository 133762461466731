export const cartItemIngredient = (ing, parentEl, updateChanges) => {
    const state = {
        ingQty: ing.ingQty
    };

    const ingHtml = `
        <div class="mt-2">
            <div class="d-flex justify-content-center ingContent">
                <div class="d-flex justify-content-center ingInfo">
                    ${ing.name} x ${ing.ingQty}: <span class="ps-2" style="font-weight: 600;">${ing.price * ing.ingQty} DIN</span> 
                </div>
                <div class="px-3 ms-2 cart_ingQty_edit">
                    <i class="bi bi-chevron-expand"></i>
                </div>
            </div>
            <div class="col-12 d-flex d-none scaleIn qtyController">
                <div class="col-3"></div>
                <div class="col-6 d-flex flex-nowrap cart_ingQty justify-content-between">
                    <div class="flex-grow-1 px-sm-1 cart_ingQty_inc ripple_effect" >
                        <i class="bi bi-plus"></i>
                    </div>
                    <div class="col-4 px-sm-1 cart_ingQty_value" >${ing.ingQty}</div>
                    <div class="flex-grow-1 px-sm-1 cart_ingQty_dec ripple_effect" >
                        <i class="bi bi-dash"></i>
                    </div>
                </div>
                <div class="col-2 d-flex align-items-center justify-content-center cart_ingQty_save">
                    <i class="bi bi-check2"></i>
                </div>
                <div class="col-1"></div>
            </div>
        </div>
    `;
    let ingElement = document.createElement('div');
    ingElement.innerHTML = ingHtml;
    ingElement = ingElement.children[0];

    if(ing.ingQty > 0)
    parentEl.appendChild(ingElement);
    
    // handle edit
    const handleEditIng = e => {
        ingContentElement.classList.add('d-none');
        qtyControllerElement.classList.remove('d-none');
    }

    // handle qty
    const handleIncQty = e => {
        state.ingQty = state.ingQty + 1;
        updateCartIngQty();
    }
    const handleDecQty = e => {
        if(state.ingQty > 0)
        state.ingQty = state.ingQty - 1;
        updateCartIngQty();
    }
    
    // save changes
    const handleSaveChanges = e => {
        ing.ingQty = state.ingQty;
        updateChanges()
        updateCartIngQty();
        
        ingContentElement.classList.remove('d-none');
        qtyControllerElement.classList.add('d-none');

        ingContentElement.classList.add('updated');
        setTimeout(() => {
            ingContentElement.classList.remove('updated');
        }, 2000);

        if(ing.ingQty < 1) 
        ingElement.remove();
    }

    const cartIngQtyElement = ingElement.querySelector('.cart_ingQty_value');
    const ingInfoElement = ingElement.querySelector('.ingInfo');
    const ingInfoHtml = () => `${ing.name} x ${ing.ingQty}: <span class="ps-2" style="font-weight: 600;">${ing.price * ing.ingQty} DIN</span>`;
    const ingContentElement = ingElement.querySelector('.ingContent');
    const qtyControllerElement = ingElement.querySelector('.qtyController');

    const updateCartIngQty = () => {
        cartIngQtyElement.innerHTML = state.ingQty;
        ingInfoElement.innerHTML = ingInfoHtml();
    }

    // edit qty
    const editIngElement = ingElement.querySelector('.cart_ingQty_edit');
    editIngElement.addEventListener('click', handleEditIng);
    
    // qty
    const incQtyElement = ingElement.querySelector('.cart_ingQty_inc');
    incQtyElement.addEventListener('click', handleIncQty);
    const decQtyElement = ingElement.querySelector('.cart_ingQty_dec');
    decQtyElement.addEventListener('click', handleDecQty);
    
    const saveChangesElement = ingElement.querySelector('.cart_ingQty_save');
    saveChangesElement.addEventListener('click', handleSaveChanges);
}